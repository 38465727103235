#cincinnati-bengals-promo{
    height: 100vh;
    background: black;

    #cincinnati-bengals-promo-wrapper{
        display: flex;
        flex-flow: column nowrap;
        padding-top: var(--headerHeight);
        box-sizing: border-box;

    }

    .copy-container{
        p{
            line-height: 2rem;
        }
    }

    #who-dey-hero{
        width: 100%;
        height: calc(100vh - var(--headerHeight));
        min-height: 500px;
        background-image: url('/images/site/cincinnati-bengals/who-dey-hero-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;

        @media screen and (min-width: 768px){
            height: 100vh;
            position: relative;
            top: calc(-1 * var(--headerHeight));
        }

        #hero-images{
            display: flex;
            align-items: flex-end;
            height: 102%; 

            #hero-img-col{
                width: 33vw;

                #who-dey-hero-img{
                    max-width: 400px;
                    max-height: 80vh;
                    margin-left: 50px;
                }
            }

            #hero-title-col{
                align-self: flex-start;
                width: 66vw;
                height: calc(100% - var(--headerHeight));
                margin-top: var(--headerHeight);
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-end;
                justify-content: space-between;

                @include phone{
                    margin-top: 0;
                    width: 100vw;
                    height: 100%;
                    background-color: rgba(0,0,0,0.45);
                }

                #hero-title-img{
                    width: 400px;
                    max-width: 40vw;
                    min-width: 15vw;
                    transform: rotate(15deg);
                    margin-right: 50px;
                    margin-top: 25px;

                    @include phone{
                        width: 75%;
                        max-width: 300px;
                        min-width: unset;
                        transform: rotate(0deg);
                        margin: 50px auto 0;
                        padding-left: 10px;
                    }
                }

                #hero-branding{
                    text-align: center;
                    margin-bottom: 60px;
                    margin-right: 100px;

                    @include phone{
                        margin: 0 auto 40px;
                    }

                    #hero-brand{
                        width: 400px;

                        @include phone{
                            width: 80%;
                            max-width: 300px;
                        }
                    }
                }

                #who-dey-xs-img{
                    height: 33vh;
                    align-self: flex-start;
                    margin-left: 20px;
                    max-height: 300px;
                }
            }
        }

    }

    #desktop-blurb, 
    #mobile-blurb{
        background: black;
        color: white;
        min-height: fit-content;
        line-height: 2rem;
    }

    #desktop-blurb{
        width: 100%;
        min-height: 15vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px){
            margin-top: calc(-1 * var(--headerHeight));
        }

        #desktop-blurb-wrapper{
            width: 85%;
            max-width: 1200px;
            margin: 25px;
            text-align: center;
        }
    }

    #mobile-blurb{
        background: white;
        color: black;
        width: calc(100% - 50px);
        margin: 50px 25px;
        text-align: center;

        @include phone{
            margin: 25px 15px;
        }
    }

    #who-dey-title{
        font-weight: 600;
    }

    #hero-copy{
        width: fit-content;
        max-width: 100%;
        position: relative;
        left: 20vw;
        top: 10vh;
        text-align: center;
        text-transform: uppercase;

        .hero-logo{
            margin: 25px;
        }

        @include phone{
            top: 0;
            left: 0;
            margin: 0 auto;
        }
    }

    #hero-img{

        img {
            width: 1000px;
        }
    }

    .mobile-hero{
        position: relative;
        left: -20px;
    }

    #who-dey-watch{
        @include inview("#who-dey-watch-img", left, 0s);
        @include inview("#who-dey-watch-copy", right, 0s);
    }

    #who-dey-read{
        @include inview("#who-dey-read-copy", left, 0s);
        @include inview("#who-dey-read-img", right, 0s);
    }

    #who-dey-discover{
        @include inview("#who-dey-discover-img", left, 0s);
        @include inview("#who-dey-discover-copy", right, 0s); 
    }

    .who-dey-link{
        color: #ba1f31;
        margin: 10px auto;
    }

    .who-dey-link:hover,
    .who-dey-link:active{
        text-decoration: underline;
    }

    #who-dey-watch,
    #who-dey-read,
    #who-dey-discover{
        background: white;
        color: black;
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 55vh;

        @include phone{
            display: flex;
            flex-flow: column nowrap;
        }

        .who-dey-img{
            background-size: cover;
            background-position: center;

            @include phone{
                min-height: 25vh;
            }
        }

        #who-dey-watch-img{
            background-image: url('/images/site/cincinnati-bengals/who-dey-watch.webp');
        }

        #who-dey-read-img{
            background-image: url('/images/site/cincinnati-bengals/who-dey-read.webp');
            @include phone{
                order: 1;
            }
        }

        #who-dey-discover-img{
            background-image: url('/images/site/cincinnati-bengals/who-dey-discover.jpg');
        }

        .who-dey-copy{

            .copy-container{
                width: fit-content;
                max-width: 85%;
                margin: 60px auto;
                

                h2 {
                    margin-bottom: 25px;
                }

                @include phone{
                    margin: 30px auto;
                }
            }
        }

        #who-dey-read-copy{

            @include phone{
                order: 2;
            }
        }

        #who-dey-watch-copy,
        #who-dey-read-copy,
        #who-dey-discover-copy{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    #who-dey-slideshow{
        height: fit-content;


        #slideshow-wrapper{
            width: 100%;
            overflow-y: hidden;
            overflow-x: hidden;
            display: block;
            margin: 60px auto 45px auto;

            @include phone{
                margin-top: 0;
                margin-bottom: 25px;

            }
        }

        #slideshow-container{
            width: 100%;
            max-width: 1200px;
            display: block;
            margin: 0 auto;

            @include phone{
                width: 100%;
            }

            .video-wrap{
	    		height: 100%;
	    	}

            .video-container {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 aspect ratio (height:width) */
                overflow: hidden;

                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

        }

        .video-slides{
            margin: 0 auto;
        }

        .video-slides,
        .video-slides .video-slide{
            

            @media screen and (min-width: 960px) {
                    width: 860px !important;
            }
        }
        .thumbnail-slides{
            cursor: pointer;

            &:active{
                cursor: grabbing;
            }
            .slick-prev,
            .slick-next{
                position: absolute;
                top: -150%;
                font-size: 16px;
                font-weight: 700;
                color: white;
                background-color: $gray;
                transition: background-color .3s ease-in-out;
                padding: 7.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;    
                cursor: pointer;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: none;
                transition: transform .1s ease-in-out;

                &:hover,
                &:active{
                    background-color: $red;
                }

                &:active{
                    transform: scale(0.95);
                }

                @media screen and (min-width: 481px) and (max-width: 767px){
                    top: -100%;
                }

                @media screen and (max-width: 480px){
                    top: -75%;
                }
            }

            .slick-prev{
                left: 0;
                @media screen and (max-width: 1300px){
                    left: 15px;
                }
            }

            .slick-next{
                right: 0%;
                @media screen and (max-width: 1300px){
                    right: 15px;
                }
            }

            .slick-list{
                height: 150px;
                padding: 15px 0 0 2px;
                margin: 0 15px;
            }

            .video-thumbnail{
                &.slick-current > .thumbnail-container{
                    transition: outline .2s ease-in-out;
                    outline: 4px solid #ba1f31;
                    outline-offset: 4px;
                }
            
                &::after{
                    content: "";
                    position: relative;
                    display: block;
                    margin: 0 auto;
                    top: -50px;
                    transform: translateY(-50%);
                    background-image: url('/images/site/cincinnati-bengals/yt_icon_rgb.png');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 40px;


                }
            
                .thumbnail-container{
                    width: 90%;
                    height: 100px;
                    background-size: cover;
                    margin: 0 auto;


                    .thumbnail-caption{
                        display: block;
                        color: white;
                        font-weight: 700;
                        font-size: 18px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), transparent);
                        width: 100%;
                        text-align: center;
                        padding-top: 2.5px;

                        @media screen and (max-width: 991px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    #who-dey-gallery{

        .carousel-control svg{
            padding: 5px;
            background: gray;
            box-shadow: 0 0 5px rgba(0,0,0,0.45);
            transition: background-color .2s ease-in-out, box-shadow .1s ease-out;
            width: 32px;
            height: 32px;
        }
    
        .carousel-control svg:hover{
            background: #ba1f31;
        }
    
        .carousel-control svg:active{
            box-shadow: none;
        }
    
        .carousel-control.left,
        .carousel-control.right{
            top: -33.3%;
            border: none;
    
            @include phone{
                display: flex;
            }
        }
    
        .carousel-control.left:after,
        .carousel-control.right:after {
            display: none;
        }
    }

    #who-dey-slideshow-gallery{
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px auto;

        #who-dey-gallery{
            background: black;
            display: grid;
            grid-template-columns: 40px 1fr 40px;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1280px;
    
            .carousel-control{
                position: unset;
                opacity: 1;
                width: 32px;
                padding: 0;
                margin: 0;
                justify-self: center;
            }          
    
            .carousel-control:active svg{
                width: 30px;
                height: 30px;
            }
    
            .carousel-control.disabled svg,
            .carosel-control.disabled:active svg{
                background-color: #444;
                outline-color: #444;
                opacity: .5;
                width: 32px;
                height: 32px;
            }
    
            #gallery-outer-wrapper{
                width: 100%;
                overflow: hidden; 
                height: unset;
                margin: 0 auto;
                display: flex;
                // justify-content: center;

                @include phone{
                    width: calc(100% - 20px);
                }
    
                #gallery-inner-wrapper{
                    width: fit-content;
                    display: flex;
                    position: relative;
                    transition: left .3s ease;
                    left: 0;
    
                    .gallery-outer-item{
                        padding: 0 25px;
                        width: calc(100vw - 100px);
                        max-width: 400px;
    
                        @include phone{
                            max-width: unset;
                            padding: 0 10px;
                        }
                    }
        
                    .gallery-item{
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: flex-end;
                        overflow: hidden;
                        flex-shrink: 0;
                        background-size: cover;
                        background-position: center;
                        height: 50vh;
                        width: 100%;
                        min-height: 500px;

                        @include phone{
                            height: 55vh;
                        }

                        .gallery-bg-img{
                            height: 100%;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: left bottom;
                        }
    
                        .item-caption-background{
                            background: white;
                            width: 100%;
                            padding: 20px;
                            box-sizing: border-box;
                            position: relative;
                            bottom: 0;
                            color: black;
                            text-align: start;
    
                            h2{
                                margin-bottom: 15px;
                                color: black;
                            }

                            p{
                                color: gray;
                                margin-bottom: 10px;
                            }
                            .read-more-link{
                                display: block;
                                padding-top: 50px;
                                text-align: end;
                                color: gray;
                                &:hover{
                                    color: #ba1f31;
                                }
                                &:active{
                                    color: #ba1f31;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    #who-dey-find{
        min-height: 550px;
        text-align: center;
        color: white;
        background-image: url('/images/site/cincinnati-bengals/who-dey-kitchen.webp');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        height: 60vh;
        overflow: visible;

        @include phone{
            background-attachment: scroll;
        }
    
		#who-dey-find-wrapper{
            background-color: rgba(0,0,0,0.45);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            .dropdown{

                @include phone{
                    width: 90%;
                    max-width: 385px;
                }

                .dropdown-toggle{
                    min-width: 385px;

                    @include phone{
                        width: 100%;
                        min-width: unset;
                    }

                    span{
                        background: black;

                        @include phone{
                            width: 40px;
                        }
                    }
                }

                .dropdown-toggle:focus:hover span{
                    background-color: #ba1f31;
                }

                .dropdown-menu{
                    max-height: 25vh;
    
                    @include phone{
                        width: 100%;
                        min-width: unset;
                        font-size: 16px;
                    }
                }
            }

            h1{
                margin: 25px auto;

                @include phone{
                    font-size: 18px !important;
                }
            }

            .dropdown-menu li a{
                line-height: normal;
                font-size: 16px;
            }
        }
    }

    #who-dey-footer{
        min-height: 425px;
        height: 50vh;
        display: grid;
        grid-template-columns: 10% 80% 10%;
        justify-content: space-around;
        align-items: center;

        @include phone{
            grid-template-columns: 15% 70% 15%;
        }

        #logo-wrapper{
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
        }

        p{
            margin-top: 25px;
            line-height: 40px;
            text-align: center;
            color: white;
        }

        #partnership-logos{
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;

            @include phone{
                width: 80%;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 75px 75px;
            }
            
            #fischer-logo{
                width: 300px;

                @include phone{
                    width: 200px;
                    max-width: 85%;
                    margin: 0 auto;
                }

                img{
                    width: 100%;
                }
            }

            #bengals-logo{
                
                @include phone{
                    margin: 0 auto;
                    width: fit-content;
                }
            }

            #bengals-logo-img{
                max-height: 150px;

                @include phone{
                    max-height: 65px;
                }
            }

            #separator{
                width: 2px;
                height: 150px;
                background: white;

                @include phone{
                    display: none;
                }
            }
        }

        .stripe{
            position: relative;
        }

        #right-stripe{
            left: 7%;
        }

        #left-stripe{
            left: -7%;
            justify-self: flex-end;
        }
    }
}